<template>
    <div>
        <div class="d-flex flex-column align-items-center bg-green rounded p-5">
            <span :class="`rounded-circle p-3 text-white bg-white`">
                <b-icon class="d-flex text-purple" icon="people-fill" font-scale="3"></b-icon>
            </span>
            <div class="text-white fs-4 mb-2">Novo Departamento</div>
            <b-button variant="light" class="text-purple px-4" v-b-modal.modalDepartmentSave>Criar</b-button>
        </div>
        <div class="mt-4 bg-white rounded text-purple">
            <div class="d-flex justify-content-between align-items-center border-bottom p-3">
                <div class="fs-5 fw-bold">Departamentos</div>
                <div class="d-flex align-items-center">
                    <div class="text-nowrap">Total de Departamentos</div>
                    <div class="fs-5 bg-purple text-white rounded-pill mx-3 px-2">{{ departments['total'] || departments.length || 0 }}</div>
                    <b-form-input type="text" placeholder="Busca por Nome Departamento" @input="filterDeps"></b-form-input>
                </div>
            </div>
            <div v-if="showDepartments().length">
                <b-row class="m-0 border-bottom px-3 py-2">
                    <b-col class="p-0" cols="9">
                        <div class="fw-bold">Nome</div>
                    </b-col>
                    <b-col>
                        
                    </b-col>
                </b-row>
                <b-row class="m-0 px-3 py-3 border-bottom d-flex align-items-center departments" v-for="item in showDepartments()" :key="item.id">
                    <b-col class="p-0" cols="9">
                        <div>{{ item.name }}</div>
                    </b-col>
                    <b-col class="p-0 d-flex justify-content-end">
                        <b-button variant="green" class="text-white manage-btn ms-0" v-b-tooltip.hover.bottom="{ variant: 'green', customClass: 'top-0', boundary: 'document' }" title="Editar" @click.prevent="selectUser(item)" v-b-modal.modalDepartmentSave>
                            <b-icon icon="pencil-square"></b-icon>
                        </b-button>
                        <b-button variant="red" class="text-white manage-btn me-0" v-b-tooltip.hover.bottom="{ variant: 'red', customClass: 'top-0', boundary: 'document' }" title="Remover" @click.prevent="selectUser(item)" v-b-modal.modalDepartmentDelete>
                            <b-icon icon="trash"></b-icon>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <div class="p-3 text-purple text-center" v-else>
                Nenhum departamento encontrado!
            </div>
        </div>
        <b-modal id="modalDepartmentSave" ref="modalDepartmentSave" :title="arrayAux._id ? 'Editar Departamento' : 'Criar Departamento'" hide-footer header-class="p-0 px-3">
            <b-form @submit.prevent="saveDepartment">
                <div>
                    <b-form-group label="Nome:" label-class="small-text fw-normal text-secondary">
                        <b-form-input v-model="arrayAux.name" required></b-form-input>
                    </b-form-group>
                </div>
                <div class="mt-4">
                    <b-button type="submit" class="modal-btn modal-btnsearch">Salvar</b-button>
                    <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modalDepartmentSave')">Cancelar</b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="modalDepartmentDelete" ref="modalDepartmentDelete" title="Remover Departamento" hide-footer size="sm" header-class="border-0 p-0 px-3" body-class="p-0">
            <div class="border-bottom border-top text-secondary smaller-text p-3">
                Tem certeza de que deseja remover este Departamento?
            </div>
            <div class="p-3">
                <b-button type="submit" class="modal-btn modal-btnsearch" @click="deleteDepartment()">Remover</b-button>
                <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modalDepartmentDelete')">Cancelar</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService.js'

export default {
    props: [
        'user'
    ],
    components: {
    },
    methods: {
        async getDepartments() {
            let resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.departments = []
                this.departments['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
            this.departments['total'] = resp.total
            // console.log('departments',this.departments)
        },
        showDepartments() {
            let deps = this.departments.filter(el => el.name.toLowerCase().includes(this.filter))
            return deps
        },
        filterDeps(e) {
            this.filter = e
        },
        async saveDepartment() {
            if(!this.arrayAux.name) {
                let msg = {
                    text:'Campo nome está vazio!',
                    success: false,
                }
                return this.$emit('msg',msg)
            }
            let isNew = false
            if(!this.arrayAux._id) {
                isNew = true
            }
            let resp = null
            if(isNew) {
                this.arrayAux.channelId = this.user.channelId || this.user.roleId
                resp = await api.createDepartment(this.arrayAux)
            } else {
                resp = await api.updateDepartment(this.arrayAux)
            }
            let msg = ''
            if(resp.statusCode == 200 || resp.statusCode == 201) {
                msg = {
                    text: `Departamento ${isNew ? 'criado' : 'editado'} com sucesso`,
                    success: true
                }
                this.$refs['modalDepartmentSave'].hide()
            } else if(resp.statusCode == 406) {
                msg = {
                    text: `Este nome de departamento já existe!`,
                    success: false
                }
            } else { 
                msg = {
                    text:'Ocorreu um erro, revise os dados e tente novamente!',
                    success: false
                }
            } 
            this.$emit('msg',msg)
            this.getDepartments()
        },
        async deleteDepartment() {
            let resp = await api.deleteDepartment(this.userSelected._id)
            let msg = ''
            if(resp.error || resp.statusCode != 200) {
                msg = {
                    text:'Ocorreu um erro!',
                    success: false
                }
            }else{
                msg = {
                    text: "Departamento removido com sucesso!",
                    success: true
                }
            }
            this.$refs['modalDepartmentDelete'].hide()
            this.$emit('msg',msg)
            this.getDepartments()
            
        },
        selectUser(item) {
            this.userSelected = item;
            this.arrayAux = Object.assign({}, item);
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.userSelected = null;
                this.arrayAux = { role:'department', channelId: this.user.channelId || this.user.roleId };
            })
        },
    },
    mounted: function() {
        this.$nextTick(function() {
            this.onCloseModal()
            this.getDepartments()
        })
    },
    created() {
    },
    data() {
        return{
            arrayAux: { role:'department', channelId: this.user.channelId || this.user.roleId },
            userSelected: null,
            departments: [],
            filter: '',
        }
    }
}
</script>

<style scoped>
    .departments:last-child{
        border-bottom: 0 !important;
    }
</style>